import React from 'react';
import 'assets/css/table.css';
import MDTypography from 'components/MDTypography';
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import DataTable from "assets/Tables/DataTable";

function SalesSummaryTable({ data }) {
  // Check if data is an array
  if (!Array.isArray(data)) {
      return (
            <>
            <MDTypography variant="body2">
                No sales summary data available.
            </MDTypography>
            </>
        );
  }
  //console.log("Summary Sales Table:", data); // Check the structure of data

  // Aggregate sales data by channel
  const summary = data.reduce((acc, curr) => {
    const { Sales_Channel, Net_Revenue, Units_Sold } = curr;
    if (!acc[Sales_Channel]) {
      acc[Sales_Channel] = { Net_Revenue: 0,  Units_Sold: 0  };
    }
    acc[Sales_Channel].Net_Revenue += Net_Revenue;
    acc[Sales_Channel].Units_Sold += Units_Sold;
    return acc;
  }, {});

  // Convert the aggregated data into rows
  const rows = Object.entries(summary).map(([channel, { Net_Revenue, Units_Sold }]) => ({
    Sales_Channel: channel,
    Net_Revenue:  `$${parseFloat(Net_Revenue).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
    Units_Sold: Number(Units_Sold).toLocaleString()
  }));

  // Define the columns
  const columns = [
    { Header: <MDTypography fontWeight="bold" variant="h6">Sales Channel</MDTypography>, accessor: "Sales_Channel"},
    { Header: <MDTypography fontWeight="bold" variant="h6">Net Revenue</MDTypography>, accessor: "Net_Revenue" },
    { Header: <MDTypography fontWeight="bold" variant="h6">Units Sold</MDTypography>, accessor: "Units_Sold" }
  ];

  return (
    <Card>
      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="dark"
      >
        <MDTypography variant="h6" color="white">Sales per Channel</MDTypography>
      </MDBox>
        <DataTable
          table={{ columns, rows  }}
          isSorted={true}
          entriesPerPage={{ defaultValue: 5, entries: [5,10,15,20,25] }}
          showTotalEntries={true}
          noEndBorder
          canSearch
          //pagination={ {variant:["gradient"], color:["primary"]}}
        />
    </Card>
  );
}

export default SalesSummaryTable;


