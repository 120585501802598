import React, { useState, useEffect } from 'react';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import Divider from "@mui/material/Divider";

import ChatBar from 'layouts/ChatBar';

import MarketingSummaryTable from './MarketingSummaryTable';
import MarketingGraph from './MarketingGraph';
import FileUpload from './../FileUpload';

const MarketingTab = () => {
  // Initialize state with data from localStorage if available
  const [data, setData] = useState(() => {
    const savedData = localStorage.getItem('marketingData');
    return savedData ? JSON.parse(savedData) : null;
  });

  // TODO Get the project from the UI
  const project = "default_brand"

   // Update localStorage when data changes
  useEffect(() => {
    localStorage.setItem('marketingData', JSON.stringify(data));
  }, [data]);

  const onUpload = (uploadType, data) => {
      setData(data); // Update marketing data with processed data
  };

  return (
     <>
        <MDBox p={3}> {/* p={3} adds padding */}
            <MDTypography variant="h3" >
                Marketing
            </MDTypography>
            <ChatBar />
            <MDTypography variant="body2">
               <FileUpload
                    uploadType="marketing"
                    onUpload={onUpload}
                    project={project}
               />
            </MDTypography>
                <Divider />
                <Divider />
                <MarketingSummaryTable data={data} />
                <Divider />
                <MarketingGraph data={data} />
        </MDBox>
     </>
    );
};

export default MarketingTab;