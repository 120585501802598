import React from 'react';

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from '@mui/material/Tooltip';

import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography';
import MDButton from "components/MDButton";
import ChatBar from 'layouts/ChatBar';

import ROIDetails from "./ROIDetails";
import StyledBoxes from "./StyledBoxes";

import "assets/css/list.css";

const ROISummaryTab = ( { onRedirectToBudgetTab }) => {

  const handleLinkClick = (e) => {
    e.preventDefault(); // Prevent the default anchor link behavior
    onRedirectToBudgetTab(); // Call the function passed from the parent component
  };

  const capitalizeFirstLetter = (string) => {
      if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const insightTooltipText = "Anonymized data collected over the past year with confirmed brands.";
  const insightStr ="Based on the audience targeting setting in your data connections consider investing $1.6MM in Snapchat; other brands with a similar profile realized an average ROI of 2.6x.";

  return (
        <MDBox p={3}> {/* p={3} adds padding */}
            <MDTypography variant="h3" >
                ROI Summary
            </MDTypography>
            <ChatBar />
            <Divider />
            <Card>
             <MDBox pt={3} px={2} >
                <MDTypography variant="h5" >Insights</MDTypography>
                <MDTypography variant="body2" pl={5} pt={2}>
                    <ul>
                        <li>
                            The champion model, PyMC national model, suggests that marketing investment has performed well with an historical incremental ROI 1.9x.
                        </li>
                        <li>
                            The Marginal ROI of 4.2 suggests that the marketing budget has room to grow up to $12MM, which would increase the ROI to 4.2x.
                        </li>
                        <li>
                            Twitter and Branded Search advertising achieved the highest Marginal ROI and our budget optimization scenario builder suggested allocating over 40% of your budget in these channels.
                        </li>
                        <li>
                            Facebook Prospecting campaigns were the lowest performing Marginal ROI suggesting a decrease in spend. See the <button onClick={handleLinkClick} style={{ background: 'none', color: 'blue', border: 'none', padding: 0, font: 'inherit', cursor: 'pointer', textDecoration: 'underline' }}><b>Budget Scenario Builder </b></button> for more details.
                        </li>
                        <li>
                            Your audience settings at X (Twitter) is causing a low ROI. Consider changing the targeting profile to mimic Facebook’s retargeting settings.
                        </li>
                    </ul>
                 </MDTypography>
             </MDBox>
             <MDBox display="flex" alignItems="center" m={3} p={1} sx={{ border: '2px solid grey', borderRadius: '7px' }}>
                 <Tooltip title={insightTooltipText}>
                      <MDButton  sx={{ textTransform: 'none', textAlign: 'left', fontSize: '16px' }}>
                      {capitalizeFirstLetter(insightStr)}
                      </MDButton>
                 </Tooltip>
             </MDBox>
             <StyledBoxes />
             <ROIDetails />
          </Card>
      <Divider />

          </MDBox>

    );
};

export default ROISummaryTab;