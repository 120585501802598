import React, { useState } from 'react';

// @mui material components
import Card from "@mui/material/Card";
import { Divider } from '@mui/material';
import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography';
import MDButton from "components/MDButton";

import CircularProgress from '@mui/material/CircularProgress';
import ChatBar from 'layouts/ChatBar';

import DateVariable from "./DateVariable";

import SalesMetric from "./sales/Metric";
import SalesPrice from "./sales/Price";
import SalesVariable from "./sales/Variable";
import SalesOthers from "./sales/Others";
import SalesChannelNames from "./sales/ChannelNames";

import AdvertisingVariable from "./advertising/Variable";
import AdvertisingAdChannels from "./advertising/AdChannels";
import AdvertisingSpendMetric from "./advertising/SpendMetric";
import AdvertisingModeledMetric from "./advertising/ModeledMetric";

import OrganicVariables from "./organic/Variables";

import UncontrollableVariables from "./uncontrollable/Variables";

const ModelingSetupTab = ({ onRedirectToReviewTab }) => {
    const [loading, setLoading] = useState(false);
    const [showModelConfiguration, setShowModelConfiguration] = useState(false);

    const handleModelConfiguration = () => {
        setShowModelConfiguration(true);
    };

    const handleStartModeling = (e) => {
        setLoading(true);
        // Simulate an async operation like an API call
        setTimeout(() => {
          setLoading(false);
          e.preventDefault(); // Prevent the default anchor link behavior
          onRedirectToReviewTab(); // Call the function passed from the parent component
        }, 2000); // You can adjust this duration as needed
    };

  return (
        <MDBox p={3}>
            <MDTypography variant="h3" >
                Modeling Setup
            </MDTypography>
            <ChatBar />
            <br />
            <Card>
            <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
               {!showModelConfiguration && (
                    <MDButton variant="contained" color="dark" onClick={handleModelConfiguration} sx={{ marginTop: 3 }} >Model Configuration</MDButton>
               )}
               {showModelConfiguration && (
                    <Card>
                        <MDTypography variant="h5" style={{ margin: '10px 5px 0 10px' }}>Date</MDTypography>
                        <MDTypography variant="body2" style={{ marginLeft: '10px' }}>Select your date variable</MDTypography>
                        <DateVariable />
                        <Divider/>
                        <MDTypography variant="h5" style={{ margin: '0 5px 0 10px' }}>Sales Metrics</MDTypography>
                        <MDTypography variant="body2" style={{ marginLeft: '10px' }}>Select your sales channels and metrics</MDTypography>
                        <MDBox sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: 2 }}>
                            <SalesVariable/>
                            <SalesChannelNames/>
                            <SalesMetric/>
                            <SalesPrice/>
                            <SalesOthers/>
                        </MDBox>
                        <Divider/>

                        <MDTypography variant="h5" style={{ margin: '0 5px 0 10px' }}>Advertising</MDTypography>
                        <MDTypography variant="body2" style={{ marginLeft: '10px' }}>Select your advertising variables and metrics</MDTypography>
                        <MDBox sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: 2 }}>
                            <AdvertisingVariable/>
                            <AdvertisingAdChannels/>
                            <AdvertisingSpendMetric/>
                            <AdvertisingModeledMetric/>
                        </MDBox>
                        <Divider/>

                        <MDTypography variant="h5" style={{ margin: '0 5px 0 10px' }}>Organic Advertising</MDTypography>
                        <MDTypography variant="body2" style={{ marginLeft: '10px' }}>Select your unpaid variables</MDTypography>
                        <MDBox sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: 2 }}>
                            <OrganicVariables/>
                        </MDBox>
                        <Divider/>

                        <MDTypography variant="h5" style={{ margin: '0 5px 0 10px' }}>Uncontrollable Metrics</MDTypography>
                        <MDTypography variant="body2" style={{ marginLeft: '10px' }}>Select variables that are outside of your control</MDTypography>
                        <MDBox sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: 2 }}>
                            <UncontrollableVariables/>
                        </MDBox>
                    </Card>
                )}
               <Divider/>
              {loading ? (
                <MDBox>
                 <CircularProgress style={{ marginLeft: '50px' }} color="dark"/>
                 <MDTypography variant="body2" style={{ marginTop: '10px' }}>Configuring model...</MDTypography>
               </MDBox>
              ) : (
                <MDButton variant="contained" color="dark" onClick={handleStartModeling} sx={{ marginBottom: 3 }} >Start Modeling</MDButton>
              )}
           </MDBox>
          </Card>
        <br />
        </MDBox>
    );
};

export default ModelingSetupTab;