import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

import fb from "assets/images/fb.png";
import tv from "assets/images/tv.png";
import x from "assets/images/x.png";
import ads from "assets/images/ads.png";

export default function data() {

   const Model = ({ image, name }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDAvatar src={image} name={name} size="sm" variant="rounded" />
          <MDTypography display="block" variant="button" fontWeight="medium" ml={1} lineHeight={1}>
            {name}
          </MDTypography>
        </MDBox>
    );

   const models = [
        {
            icon: <Model image={fb} name="" />,
            marketingChannel:  (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                Facebook Prospecting
              </MDTypography>
            ),
            spend: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                $3,477,386
              </MDTypography>
            ),
            lift: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                6.08%
              </MDTypography>
            ),
            incrementalROI: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                2.0x
              </MDTypography>
            ),
            marginalROI: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                1.7x
              </MDTypography>
            ),
        },
         {
            icon: <Model image={x} name="" />,
            marketingChannel:  (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                X Prospecting
              </MDTypography>
            ),
            spend: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                $2,373,501
              </MDTypography>
            ),
            lift: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                1.34%
              </MDTypography>
            ),
            incrementalROI: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                0.7x
              </MDTypography>
            ),
            marginalROI: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                4.3x
              </MDTypography>
            ),
        },
        {
            icon: <Model image={tv} name="" />,
            marketingChannel:  (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                Linear TV
              </MDTypography>
            ),
            spend: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                $1,261,736
              </MDTypography>
            ),
            lift: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                0.43%
              </MDTypography>
            ),
            incrementalROI: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                0.4x
              </MDTypography>
            ),
            marginalROI: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                3.5x
              </MDTypography>
            ),
        },
         {
            icon: <Model image={ads} name="" />,
            marketingChannel:  (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                Search Non Branded
              </MDTypography>
            ),
            spend: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                $96,793
              </MDTypography>
            ),
            lift: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                0.30%
              </MDTypography>
            ),
            incrementalROI: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                3.6x
              </MDTypography>
            ),
            marginalROI: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                3.6x
              </MDTypography>
            ),
        },
         {
            icon: <Model image={ads} name="" />,
            marketingChannel:  (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                Search Branded
              </MDTypography>
            ),
            spend: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                $86,075
              </MDTypography>
            ),
            lift: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                0.53%
              </MDTypography>
            ),
            incrementalROI: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                7.2x
              </MDTypography>
            ),
            marginalROI: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                5.8x
              </MDTypography>
            ),
        },
         {
            icon: <Model image={fb} name="" />,
            marketingChannel:  (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                Facebook Retargeting
              </MDTypography>
            ),
            spend: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                $49,745
              </MDTypography>
            ),
            lift: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                0.40%
              </MDTypography>
            ),
            incrementalROI: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                9.3x
              </MDTypography>
            ),
            marginalROI: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                2.1x
              </MDTypography>
            ),
        },
   ];

  return {
    columns: [
      { Header: "", accessor: "icon", width:"2%", align: "left" },
      { Header: <MDTypography fontWeight="bold" variant="h6">Marketing Channel</MDTypography>, accessor: "marketingChannel", width:"20%", align: "left" },
      { Header: <MDTypography fontWeight="bold" variant="h6">Spend</MDTypography>, accessor: "spend", align: "left" },
      { Header: <MDTypography fontWeight="bold" variant="h6">% Lift</MDTypography>, accessor: "lift", align: "center" },
      { Header: <MDTypography fontWeight="bold" variant="h6">Incremental ROI</MDTypography>, accessor: "incrementalROI", align: "left" },
      { Header: <MDTypography fontWeight="bold" variant="h6">Marginal ROI</MDTypography>, accessor: "marginalROI", align: "center" },
    ],

    rows: models.map(model => ({
      icon: model.icon,
      marketingChannel: model.marketingChannel,
      spend: model.spend,
      lift: model.lift,
      incrementalROI: model.incrementalROI,
      marginalROI: model.marginalROI,
    })),
  };
}