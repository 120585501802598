import React, { useState } from 'react';

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React example components
import DashboardLayout from "assets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "assets/Navbars/DashboardNavbar";
import Footer from "assets/Footer";

import BrandsTab from 'layouts/data-integration/components/BrandsTab';
import SalesTab from 'layouts/data-integration/components/SalesTab';
import MarketingTab from 'layouts/data-integration/components/MarketingTab';
import OthersTab from 'layouts/data-integration/components/OthersTab';
import DataLogTab from 'layouts/data-integration/components/DataLogTab';

function DataIntegration() {
  const [tabValue, setTabValue] = useState(0);
  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };
  const tabStyle = (index) => ({
    border: tabValue === index ? '2px solid #247EEA' : 'none'
  });

  return (
     <DashboardLayout>
          <DashboardNavbar />
           <AppBar position="static">
              <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  label="Sales"
                  icon={ <Icon fontSize="small" sx={{ mt: -0.25 }}>leaderboard</Icon> }
                  style={tabStyle(0)}
                />
                <Tab
                  label="Marketing"
                  icon={ <Icon fontSize="small" sx={{ mt: -0.25 }}>assessment</Icon> }
                  style={tabStyle(1)}
                />
                <Tab
                  label="Others"
                  icon={ <Icon fontSize="small" sx={{ mt: -0.25 }}>article</Icon> }
                  style={tabStyle(2)}
                />
                <Tab
                  label="Data Log"
                  icon={ <Icon fontSize="small" sx={{ mt: -0.25 }}>backup</Icon> }
                  style={tabStyle(3)}
               />
              </Tabs>
         </AppBar>
         {tabValue === 0 && <SalesTab />}
         {tabValue === 1 && <MarketingTab />}
         {tabValue === 2 && <OthersTab />}
         {tabValue === 3 && <DataLogTab />}
        <Footer />
      </DashboardLayout>
    );
}

export default DataIntegration;
