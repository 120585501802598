import React from 'react';

// @mui material components
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography';

import deck from "assets/images/deck.png";

import ChatBar from 'layouts/ChatBar';

const DeckBuilderTab = () => {
    return (
        <MDBox p={3} pb={3} pr={5}>
            <MDTypography variant="h3" >
                Deck Builder
            </MDTypography>
            <ChatBar />
            <br/>
            <Card >
             <MDTypography variant="body2" > </MDTypography>
             <div class="centerSampleDeckImg">
                <img src={deck} alt=""/>
            </div>
            <br/>
          </Card>
        </MDBox>
    );
};

export default DeckBuilderTab;