import { useState } from "react";

import DashboardLayout from "assets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "assets/Navbars/DashboardNavbar";
//import HorizontalMenu from "layouts/ai-insights/components/HorizontalMenu";
import Footer from "assets/Footer";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

import RevenueSourcesTab from 'layouts/ai-insights/components/RevenueSourcesTab';
import BudgetAllocatorTab from 'layouts/ai-insights/components/BudgetAllocatorTab';
import DeckBuilderTab from 'layouts/ai-insights/components/DeckBuilderTab';
import ROISummaryTab from 'layouts/ai-insights/components/ROISummaryTab';

function AIInsights() {
  const [tabValue, setTabValue] = useState(0);
  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };
  const tabStyle = (index) => ({
    border: tabValue === index ? '2px solid #247EEA' : 'none'
  });

  const redirectToROI = () => {
    setTabValue(1); // Set tabValue to 1 to switch to ROISummaryTab
  };

   return (
    <DashboardLayout>
      <DashboardNavbar />
        <AppBar position="static">
              <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  label="Revenue Sources"
                  icon={ <Icon fontSize="small" sx={{ mt: -0.25 }}>paid</Icon> }
                  style={tabStyle(0)}
                />
                <Tab
                  label="ROI Summary"
                  icon={ <Icon fontSize="small" sx={{ mt: -0.25 }}>dataset</Icon> }
                  style={tabStyle(1)}
                />
                <Tab
                  label="Budget Allocator"
                  icon={ <Icon fontSize="small" sx={{ mt: -0.25 }}>checklist</Icon> }
                  style={tabStyle(2)}
                />
                <Tab
                  label="Deck Builder"
                  icon={ <Icon fontSize="small" sx={{ mt: -0.25 }}>business</Icon> }
                  style={tabStyle(3)}
                />
              </Tabs>
         </AppBar>
         {tabValue === 0 && <RevenueSourcesTab onRedirectToBudgetTab={redirectToROI} />}
         {tabValue === 1 && <ROISummaryTab />}
         {tabValue === 2 && <BudgetAllocatorTab />}
         {tabValue === 3 && <DeckBuilderTab />}
      <Footer />
    </DashboardLayout>
  );
}

export default AIInsights;
