import React, { useState } from 'react';

import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";


function ChatBar() {
  const [inputText, setInputText] = useState('');

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleSend = () => {
    console.log('Sending ChatBar text:', inputText);
    // Implement sending message logic
  };

  return (
    <MDBox height="60px"  display="flex" justifyContent="left" alignItems="center" gap="5px">
      <MDButton color="dark" onClick={handleSend}>+</MDButton>
      <MDInput
        type="text"
        placeholder="Ask me anything (MMM related)"
        value={inputText}
        onChange={handleInputChange}
        sx={{ width: "100%" }}
      />
    </MDBox>
  );
}

export default ChatBar;

