import React, { useState, useEffect } from 'react';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import Divider from "@mui/material/Divider";

import ChatBar from 'layouts/ChatBar';
import SalesSummaryTable from './SalesSummaryTable';
import SalesGraph from './SalesGraph';
import FileUpload from './../FileUpload';

const SalesTab = () => {
  // Initialize state with data from localStorage if available
  const [data, setData] = useState(() => {
    const savedData = localStorage.getItem('salesData');
    return savedData ? JSON.parse(savedData) : null;
  });

  // TODO Get the project from the UI
  const project = "default_brand"

   // Update localStorage when data changes
  useEffect(() => {
    localStorage.setItem('salesData', JSON.stringify(data));
  }, [data]);

  const onUpload = (uploadType, data) => {
      setData(data); // Update sales data with processed data
  };
  return (
     <>
        <MDBox p={3}> {/* p={3} adds padding */}
            <MDTypography variant="h3" >
                Sales
            </MDTypography>
            <ChatBar />
            <MDTypography variant="body2">
               <FileUpload
                    uploadType="sales"
                    onUpload={onUpload}
                    project={project}
                />
            </MDTypography>
              <Divider />
              <Divider />
              <SalesSummaryTable data={data} />
              <Divider />
              <SalesGraph data={data} />
        </MDBox>
     </>
    );
};

export default SalesTab;