import DataIntegration from "layouts/data-integration";
import Modeling from "layouts/modeling";
import AIInsights from "layouts/ai-insights";
import ModelDetails from "layouts/model-details";
import LogOut from "layouts/authentication/logout";
// @mui icons
import Icon from "@mui/material/Icon";

import getAccountUrl from "./config";

const accountUrl = getAccountUrl();

const routes_member = [
  {
    type: "collapse",
    name: "Data Integration",
    key: "data-integration",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/data-integration",
    component: <DataIntegration />,
  },
  {
    type: "collapse",
    name: "Modeling",
    key: "modeling",
    icon: <Icon fontSize="small">dataset</Icon>,
    route: "/modeling",
    component: <Modeling />,
  },
  {
    type: "collapse",
    name: "AI Insights",
    key: "ai-insights",
    icon: <Icon fontSize="small">insights</Icon>,
    route: "/ai-insights",
    component: <AIInsights />,
  },
  {
    type: "collapse",
    name: "Model Details",
    key: "model-details",
    icon: <Icon fontSize="small">details</Icon>,
    route: "/model-details",
    component: <ModelDetails />,
  },
  {
    type: "collapse",
    name: "Account",
    icon: <Icon fontSize="small">person</Icon>,
    route: accountUrl+'/account',
  },
  {
    type: "collapse",
    name: "Log Out",
    key: "logout",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/authentication/logout",
    component: <LogOut />,
  }
];

export default routes_member;
