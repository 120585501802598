import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "@propelauth/react";

// Material React Context Provider
import { MaterialUIControllerProvider } from "context";

import getAccountUrl from "./config";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const container = document.getElementById("app");
const root = createRoot(container);
const accountUrl = getAccountUrl();

root.render(
  <React.StrictMode>
    <AuthProvider authUrl={accountUrl}>
        <BrowserRouter>
          <MaterialUIControllerProvider>
            <App />
          </MaterialUIControllerProvider>
        </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
