import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

import google_mmm from "assets/images/google-mmm.png";
import pymc from "assets/images/pymc.png";
import robyn from "assets/images/robyn.png";


export default function businessResultsData() {

   const Model = ({ image, name }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDAvatar src={image} name={name} size="xss" variant="rounded" />
          <MDTypography display="block" variant="button" fontWeight="medium" ml={1} lineHeight={1}>
            {name}
          </MDTypography>
        </MDBox>
    );

   const models = [
        {
            icon: <Model image={robyn} name="Facebook's Robyn" />,
            totalSpend:  (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                $6.34MM
              </MDTypography>
            ),
            roi: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                2.8x
              </MDTypography>
            ),
            lift: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                100%
              </MDTypography>
            ),
        },
        {
            icon: <Model image={pymc} name="PyMC" />,
           totalSpend:  (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                $6.34MM
              </MDTypography>
            ),
            roi: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                1.9x
              </MDTypography>
            ),
            lift: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                71%
              </MDTypography>
            ),
        },
        {
            icon: <Model image={google_mmm} name="Google Lightweight MMM" />,
           totalSpend:  (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                $6.34MM
              </MDTypography>
            ),
            roi: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                1.6x
              </MDTypography>
            ),
            lift: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                69%
              </MDTypography>
            ),
        },
        {
            icon: <Model image={""} name="Naïve" />,
           totalSpend:  (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                $6.34MM
              </MDTypography>
            ),
            roi: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                2.8x
              </MDTypography>
            ),
            lift: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                100%
              </MDTypography>
            ),
        },
        {
           icon: <Model image={""} name="Multiplicative MMM" />,
           totalSpend:  (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
              </MDTypography>
            ),
            roi: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
              </MDTypography>
            ),
            lift: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
              </MDTypography>
            ),
        },
       {
           icon: <Model image={""} name="Time-Varying Model" />,
           totalSpend:  (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
              </MDTypography>
            ),
            roi: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
              </MDTypography>
            ),
            lift: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
              </MDTypography>
            ),
        },
       {
           icon: <Model image={""} name="Nest Model" />,
           totalSpend:  (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
              </MDTypography>
            ),
            roi: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
              </MDTypography>
            ),
            lift: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
              </MDTypography>
            ),
        },
   ];

  return {
    columns: [
      { Header: "", accessor: "icon", align: "left" },
      { Header: <MDTypography fontWeight="bold" variant="h7">Total Spend</MDTypography>, accessor: "totalSpend", align: "left" },
      { Header: <MDTypography fontWeight="bold" variant="h7">Return on Investment</MDTypography>, accessor: "roi", align: "left" },
      { Header: <MDTypography fontWeight="bold" variant="h7">% Lift</MDTypography>, accessor: "lift", align: "center" },
    ],

    rows: models.map(model => ({
      icon: model.icon,
      totalSpend: model.totalSpend,
      roi: model.roi,
      lift: model.lift,
    })),
  };
}