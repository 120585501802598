import React from 'react';
import Paper from '@mui/material/Paper';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

function StyledBoxes() {
    // Array of texts for each box
    const boxTexts = [
        "Total Marketing Budget: $6.3MM",
        "Incremental ROI: 1.9x",
        "Marginal ROI: 4.2x"
    ];

    return (
        <MDBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'left', gap: 3, p: 3}}>
            {boxTexts.map((text, index) => (
                <Paper
                    key={index}
                    elevation={4}
                    sx={{
                        width: 300,
                        height: 80,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        backgroundColor: '#f5f5f5',
                        '&:hover': {
                            backgroundColor: '#e8e8e8',
                        },
                    }}
                >
                    <MDTypography variant="h6" color="text">
                        {text}
                    </MDTypography>
                </Paper>
            ))}
        </MDBox>
    );
}

export default StyledBoxes;
