import React from 'react';
import ChatBar from 'layouts/ChatBar';

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const OthersTab = () => {
    return (
      <>
        <MDBox p={3}> {/* p={3} adds padding */}
            <MDTypography variant="h3" >
                Others
            </MDTypography>
           <ChatBar />
        </MDBox>
      <Card>
          <MDBox pt={3} px={2} height="300px">
            <MDTypography variant="h6" fontWeight="medium">Coming soon...</MDTypography>
            <MDTypography variant="body2">
                TBD
            </MDTypography>
          </MDBox>
       </Card>
      <Divider />
    </>
    );
};

export default OthersTab;