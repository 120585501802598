import React from 'react';

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Divider from "@mui/material/Divider";

import DataTable from "assets/Tables/DataTable";
// Data
import dataROI from "layouts/ai-insights/components/data/dataROI";

import matrixROI from "assets/images/matrixROI.png";

const ROIDetails = () => {
    const { columns, rows } = dataROI();

    return (
        <MDBox pt={6} pb={3} pr={5}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                <MDTypography variant="h6" color="white">Spend & ROI Details</MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
            <Divider />
               <div class="centerSampleImg">
                <img src={matrixROI} alt="Matrix ROI"/>
               </div>
          </Grid>
        </Grid>
      </MDBox>
    );
};

export default ROIDetails;
