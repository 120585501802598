import MDTypography from "components/MDTypography";

export default function data() {

   const models = [
        {
            inSample: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                90.2%
              </MDTypography>
            ),
            outSample: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                65.3%
              </MDTypography>
            ),
            dw: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                0.5
              </MDTypography>
            ),
            vif: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                21.2
              </MDTypography>
            ),
        },
        {
            inSample: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                85.3%
              </MDTypography>
            ),
            outSample: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                81.6%
              </MDTypography>
            ),
            dw: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                1.8
              </MDTypography>
            ),
            vif: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                5.6
              </MDTypography>
            ),
        },
        {
            inSample: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                82.0%
              </MDTypography>
            ),
            outSample: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                80.3%
              </MDTypography>
            ),
            dw: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                1.6
              </MDTypography>
            ),
            vif: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                9.3
              </MDTypography>
            ),
        },
        {
            inSample: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                78.7%
              </MDTypography>
            ),
            outSample: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                53.9%
              </MDTypography>
            ),
            dw: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                0.2
              </MDTypography>
            ),
            vif: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                39.4
              </MDTypography>
            ),
        },
        {
            inSample: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
              </MDTypography>
            ),
            outSample: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
              </MDTypography>
            ),
            dw: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
              </MDTypography>
            ),
            vif: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
              </MDTypography>
            ),
        },
       {
            inSample: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
              </MDTypography>
            ),
            outSample: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
              </MDTypography>
            ),
            dw: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
              </MDTypography>
            ),
            vif: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
              </MDTypography>
            ),
        },
       {
            inSample: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
              </MDTypography>
            ),
            outSample: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
              </MDTypography>
            ),
            dw: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
              </MDTypography>
            ),
            vif: (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
              </MDTypography>
            ),
        },
   ];

  return {
    columns: [
      { Header: <MDTypography fontWeight="bold" variant="h7">In-Sample Accuracy</MDTypography>, accessor: "inSample", align: "left" },
      { Header: <MDTypography fontWeight="bold" variant="h7">Out-of-Sample Accuracy</MDTypography>, accessor: "outSample", align: "center" },
      { Header: <MDTypography fontWeight="bold" variant="h7">Variable Selection*</MDTypography>, accessor: "dw", align: "center" },
      { Header: <MDTypography fontWeight="bold" variant="h7">Multicolinearity**</MDTypography>, accessor: "vif", align: "center" },
    ],

    rows: models.map(model => ({
      inSample: model.inSample,
      outSample: model.outSample,
      dw: model.dw,
      vif: model.vif,
    })),
  };
}