import React from 'react';
import 'assets/css/table.css';

import MDTypography from 'components/MDTypography';
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";

import DataTable from "assets/Tables/DataTable";

function MarketingSummaryTable({ data }) {
  // Check if data is an array
  if (!Array.isArray(data)) {
      return (
            <>
            <MDTypography variant="body2">
                No marketing summary data available.
            </MDTypography>
            </>
        );
  }

  // Aggregate marketing data by channel
  const summary = data.reduce((acc, curr) => {
    const { Marketing_Channel, Ad_Spend, Impressions, Clicks } = curr;
    if (!acc[Marketing_Channel]) {
      acc[Marketing_Channel] = { Ad_Spend: 0, Impressions: 0, Clicks: 0 };
    }
    acc[Marketing_Channel].Ad_Spend += Ad_Spend;
    acc[Marketing_Channel].Impressions += Impressions;
    acc[Marketing_Channel].Clicks += Clicks;
    return acc;
  }, {});

  // Convert the aggregated data into rows
  const rows = Object.entries(summary).map(([channel, { Ad_Spend, Impressions, Clicks }]) => ({
    Marketing_Channel: channel,
    Ad_Spend: `$${parseFloat(Ad_Spend).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
    Impressions: Number(Impressions).toLocaleString(),
    Clicks: Number(Clicks).toLocaleString(),
  }));

  // Define the columns
  const columns = [
    { Header: <MDTypography fontWeight="bold" variant="h6">Marketing Channel</MDTypography>, accessor: "Marketing_Channel"},
    { Header: <MDTypography fontWeight="bold" variant="h6">Ad Spend</MDTypography>, accessor: "Ad_Spend" },
    { Header: <MDTypography fontWeight="bold" variant="h6">Impressions</MDTypography>, accessor: "Impressions" },
    { Header: <MDTypography fontWeight="bold" variant="h6">Clicks</MDTypography>, accessor: "Clicks" }
  ];

  return (
     <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="dark"
          >
          <MDTypography variant="h6" color="white">Marketing per Channel</MDTypography>
          </MDBox>
            <DataTable className="summary-table"
              table={{ columns, rows }}
              isSorted={true}
              entriesPerPage={{ defaultValue: 5, entries: [5,10,15,20,25] }}
              showTotalEntries={true}
              noEndBorder
              canSearch
            />
    </Card>
  );
}

export default MarketingSummaryTable;

