import { useState } from "react";

import DashboardLayout from "assets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "assets/Navbars/DashboardNavbar";
//import HorizontalMenu from "layouts/model-details/components/HorizontalMenu";
import Footer from "assets/Footer";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

import ChannelDiscoveryTab from 'layouts/model-details/components/ChannelDiscoveryTab';
import ModelStatisticsTab from 'layouts/model-details/components/ModelStatisticsTab';

function ModelDetails() {
  const [tabValue, setTabValue] = useState(0);
  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };
  const tabStyle = (index) => ({
    border: tabValue === index ? '2px solid #247EEA' : 'none'
  });

 return (
    <DashboardLayout>
      <DashboardNavbar />
            <AppBar position="static">
              <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  label="Channel Discovery"
                  icon={ <Icon fontSize="small" sx={{ mt: -0.25 }}>analytics</Icon> }
                  style={tabStyle(0)}
                />
                <Tab
                  label="Model Statistics"
                  icon={ <Icon fontSize="small" sx={{ mt: -0.25 }}>insights</Icon> }
                  style={tabStyle(1)}
                />
              </Tabs>
            </AppBar>
            {tabValue === 0 && <ChannelDiscoveryTab />}
            {tabValue === 1 && <ModelStatisticsTab />}
      <Footer />
    </DashboardLayout>
  );
}

export default ModelDetails;
