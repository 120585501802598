import React from 'react';

import ChatBar from 'layouts/ChatBar';

import 'assets/css/table.css';

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const DataLogTab = () => {
    return (
      <>
        <MDBox p={3}> {/* p={3} adds padding */}
            <MDTypography variant="h3" >
                Data Log
            </MDTypography>
            <ChatBar />
        </MDBox>
         <Card>
          <MDBox pt={2} px={2} pb={3} >
            <MDTypography variant="h6" fontWeight="medium">Sales Data Analysis</MDTypography>
            <MDTypography variant="body2">
                The sales data file contains 1 sales channel with 104 observations with weekly frequency.
                No missing data detected.
            </MDTypography>
            <Divider />
            <MDTypography variant="h6" fontWeight="medium">Marketing Data Analysis</MDTypography>
            <MDTypography variant="body2">
                The marketing data file contains two missing weeks. They were replaced with zeros. Check data configurations for missing data handling.
            <Divider />
                <table className="log-table">
                    <tr>
                        <th>Marketing Channel</th>
                        <th>Week</th>
                    </tr>
                    <tr>
                        <td>facebook_retargeting</td>
                        <td>Apr 4, 2022</td>
                    </tr>
                    <tr>
                        <td>facebook_retargeting</td>
                        <td>Nov 6, 2023</td>
                    </tr>
                </table>
            <Divider />
            tv_linear contained two values on the same week of Dec 4, 2023. These values have been summed up together. Check the data configurations tab for duplicate values handling.

            </MDTypography>
          </MDBox>
       </Card>
      <Divider />
      </>
    );
};

export default DataLogTab;
