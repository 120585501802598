import { useEffect } from "react";
// react-router components
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";


// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import brandWhite from "assets/images/mmmlabs-logo.png";

// Material Dashboard 2 React example components
import Sidenav from "assets/Sidenav";
import theme from "assets/theme";

import routes_admin from "routes_admin";
import routes_member from "routes_member";
import routes_trial from "routes_trial";


// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";

// PropelAuth
import { useAuthInfo, withAuthInfo, useRedirectFunctions } from "@propelauth/react";

// Registration Component
import SignUp from "layouts/registration/SignUp";

const App = withAuthInfo((props) => {
    const authInfo = useAuthInfo();
    const { redirectToLoginPage } = useRedirectFunctions()
    const [controller] = useMaterialUIController();
    const { layout} = controller;

    useEffect(() => {
        localStorage.clear();      // Clearing localStorage on the first render
    }, []); // The empty array means this effect runs once after the initial render

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
        if (route.collapse) {
            return getRoutes(route.collapse);
        }
        if (route.route) {
            return <Route exact path={route.route} element={route.component} key={route.key} />;
        }
        return null;
    });

    // Component for navigation buttons
    const NavigationButtons = () => {
        const navigate = useNavigate();
        return (
            <>
                <button onClick={() => redirectToLoginPage()}>Login</button>
                <button onClick={() => navigate("/signup")}>SignUp</button>
            </>
        );
    };

  if (props.isLoggedIn) {
      // Check if the user has the right role to see the pages. Users belong to only one Organization. Get the first one
      const userRole = authInfo.orgHelper.getOrgs()[0].userAssignedRole;
      console.log("User role: ", userRole);
      console.log("Bearer Token: ", authInfo.accessToken);
      console.log("REACT_APP_ENVIRONMENT: ", process.env.REACT_APP_ENVIRONMENT);
      console.log("REACT_APP_API_URL: ", process.env.REACT_APP_API_URL);
      console.log("REACT_APP_API_BASE_URL: ", process.env.REACT_APP_API_BASE_URL);

      const determineRoutes = (userRole) => {
            if (userRole === "Trial") {
                return getRoutes(routes_trial);
            } else if (userRole === "Admin" || userRole === "Owner") {
                return getRoutes(routes_admin);
            } else {
                return getRoutes(routes_member);
            }
      };

      return  (
        <ThemeProvider theme={ theme }>
          <CssBaseline />
          {layout === "data-integration" && (
              <Sidenav
                    props={props}
                    brand={ brandWhite }
                    brandName="Data Explorer"
                    routes={
                          userRole === "Admin" || userRole === "Owner"
                           ? routes_admin
                           : userRole === "Trial"
                           ? routes_trial
                           : routes_member
                    }
              />
          )}
          <Routes>
            {determineRoutes(userRole)}
            <Route path="*" element={<Navigate to="/data-integration" />} />
          </Routes>
        </ThemeProvider>
      );
   } else {
        return (
        <div>
            <div className="container">
                <div className="centered-box">
                    <div className="user-section-logged-off">
                        <a href="https://mmmlabs.ai">
                          <img src={brandWhite} alt="MMM Labs: Data Explorer" className="logo-landing-page" />
                        </a>
                        <div className="logoff-text">You are not logged in</div>
                        <NavigationButtons />
                    </div>
                    <div className="logoff-text">
                        Start your MMM by uploading your sales & marketing data
                    </div>
                </div>
            </div>
            <Routes>
                <Route path="/signup" element={<SignUp />} />
            </Routes>
        </div>
        );
   }
});

export default App;