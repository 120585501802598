import React from 'react';

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography';

import ChatBar from 'layouts/ChatBar';
import modelDetails1 from "assets/images/modelDetails1.png";
import modelDetails2 from "assets/images/modelDetails2.png";


const ChannelDiscoveryTab = () => {
    return (
      <MDBox pt={6} pb={3} pr={5}>
            <MDTypography variant="h3" >
                Channel Discovery
            </MDTypography>
            <ChatBar />
            <Divider />
            <Card>
            <MDTypography variant="body2" p={2}>
              Select a channel, see text based insights about the channel/variable effectiveness, current spend, optimal spend, and points of diminishing returns. Then followed by some graphs such as:
            </MDTypography>
             <Divider />
             <div class="centerSampleImg">
                <img src={modelDetails1} alt=""/>
                <img src={modelDetails2} alt=""/>
            </div>
          </Card>
         <Divider/>
        </MDBox>
    );
};

export default ChannelDiscoveryTab;