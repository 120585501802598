import { useState } from "react";

import DashboardLayout from "assets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "assets/Navbars/DashboardNavbar";
import Footer from "assets/Footer";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

import ModelingSetupTab from 'layouts/modeling/components/ModelingSetupTab';
import ResultsReviewTab from 'layouts/modeling/components/ResultsReviewTab';

function Modeling() {
  const [tabValue, setTabValue] = useState(0);
  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };
  const tabStyle = (index) => ({
    border: tabValue === index ? '2px solid #247EEA' : 'none'
  });

  const redirectToReview = () => {
    setTabValue(1);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
        <AppBar position="static">
              <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  label="Modeling Setup"
                  icon={ <Icon fontSize="small" sx={{ mt: -0.25 }}>settings</Icon> }
                  style={tabStyle(0)}
                />
                <Tab
                  label="Results Review"
                  icon={ <Icon fontSize="small" sx={{ mt: -0.25 }}>grading</Icon> }
                  style={tabStyle(1)}
                />
              </Tabs>
          </AppBar>
         {tabValue === 0 && <ModelingSetupTab onRedirectToReviewTab={redirectToReview}/>}
         {tabValue === 1 && <ResultsReviewTab />}
      <Footer />
    </DashboardLayout>
  );
}

export default Modeling;
