// react-router-dom components
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import { useLogoutFunction, useRedirectFunctions } from '@propelauth/react';

function Cover( {isLoggedIn} ) {
  const logoutFn = useLogoutFunction()
  const { redirectToLoginPage } = useRedirectFunctions()
  return (
  <BasicLayout image="">
      <Card>
         <MDTypography  p={2} variant="body2" justifyContent="center" align="center">
            Are you sure you want to log out?
         </MDTypography>
         <MDBox pb={2} display="flex" gap={3} justifyContent="center" alignItems="center">
           <MDButton component={Link} onClick={redirectToLoginPage}  color="dark">Cancel</MDButton>
           <MDButton component={Link} onClick={logoutFn}  color="dark">Log out</MDButton>
         </MDBox>
      </Card>
    </BasicLayout>
    );
}

export default Cover;
