import DataIntegration from "layouts/data-integration";
import AIInsights from "layouts/ai-insights";
import LogOut from "layouts/authentication/logout";
// @mui icons
import Icon from "@mui/material/Icon";

import getAccountUrl from "./config";

const accountUrl = getAccountUrl();

const routes_trial = [
  {
    type: "collapse",
    name: "Data Integration",
    key: "data-integration",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/data-integration",
    component: <DataIntegration />,
  },
  {
    type: "collapse",
    name: "Account",
    icon: <Icon fontSize="small">person</Icon>,
    route: accountUrl+'/account',
  },
  {
    type: "collapse",
    name: "Log Out",
    key: "logout",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/authentication/logout",
    component: <LogOut />,
  }
];

export default routes_trial;
