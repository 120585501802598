import React from 'react';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

import BusinessResults from "./BusinessResults";
import StatisticalValidation from "./StatisticalValidation";
import ChatBar from 'layouts/ChatBar';

import "assets/css/list.css";

const ResultsReviewTab = () => {
  return (
   <>
        <MDBox p={3}>
            <MDTypography variant="h3" >
                Results Review
            </MDTypography>
            <ChatBar />
            <Divider />
            <Card>
              <MDBox pt={3} px={2} >
                <MDTypography variant="h5" >Insights</MDTypography>
                <MDTypography variant="body2">
                   <ul class="with-bullets">
                    <li><u><b>Best Model:</b></u> The best model to choose is based on the PyMC Bayesian formation because it has the most reasonable in-sample/out-of-sample accuracy.</li>
                    <li><u><b>Business Results:</b></u> The resulting ROI of 1.9x contributed to 71% sales lift.</li>
                    <li><u><b>Statistical Checks:</b></u></li>
                      <ul class="with-bullets-indent">
                        <li><u>Variable Selection</u> statistic (DW) of 1.8 suggests that all relevant variables are included.</li>
                        <li><u>Multicolinearity Indicator</u> (VIF) of 5.6 resulting in low multicollinearity in the data.</li>
                        <li><u>Weighted P-values</u> of 0.07 suggests a good statistical fit of the marketing variables.</li>
                      </ul>
                   </ul>
                    <Divider />
                    Other models are included for comparison.
                </MDTypography>
                <MDTypography variant="body2">
                    <MDBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'left', gap: 1, p:1, mt:5}}>
                        <BusinessResults />
                        <StatisticalValidation />
                    </MDBox>
                </MDTypography>
              </MDBox>
           </Card>
        </MDBox>
</>
    );
};

export default ResultsReviewTab;
