import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 205,
    },
  },
};

const names = [
  'Revenue',
];

function getStyles(name, metric, theme) {
  return {
    fontWeight:
      metric.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelectChip() {
  const theme = useTheme();
  const [metric, setMetric] = React.useState(['Revenue']);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setMetric(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
      <FormControl sx={{ m: 1, width: 205}}>
        <InputLabel id="date-variable-label">Metric</InputLabel>
        <Select
          sx={{ margin: '8px', padding: '5px 0'}}
          labelId="date-variable-label"
          id="multiple-date-variable"
          multiple
          value={metric}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-date-variable" label="Metric" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} sx={{ height: '10px', padding: '8px 1px' }} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem
              sx={{ height: '15px', padding: '10px', marginTop: '5px' }}
              key={name}
              value={name}
              style={getStyles(name, metric, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  );
}
