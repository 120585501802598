import React from 'react';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

import ChatBar from 'layouts/ChatBar';
import revenueSources from "assets/images/revenue-sources.png";

const RevenueSourcesTab = () => {
  return (
   <MDBox pt={6} pb={3} pr={5}>
            <MDTypography variant="h3" >
                Revenue Sources
            </MDTypography>
            <ChatBar />
            <Divider />
            <Card>
             <div class="centerSampleImg">
                <img src={revenueSources} alt=""/>
            </div>
          </Card>
         <Divider/>
        </MDBox>
    );
};

export default RevenueSourcesTab;
