import React from 'react';

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DataTable from "assets/Tables/DataTable";
// Data
import data from "layouts/modeling/components/data/businessResultsData";

import "assets/css/table.css";

const BusinessResults = () => {
    const { columns, rows } = data();

    return (

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">Business Results</MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
             <MDTypography pt={2} variant="body2">
                 * Is the Durbin Watson (DW) test<br/>
                ** Is a weighted Variable Inflation Factor (VIF) weighted by the spend
             </MDTypography>
          </Grid>
        </Grid>

    );
};

export default BusinessResults;
