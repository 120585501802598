import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import MDTypography from 'components/MDTypography';

import { InputLabel } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

import {useAuthInfo} from "@propelauth/react"

function FileUpload({ uploadType, onUpload, project }) {
  const authInfo = useAuthInfo();

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFilename, setSelectedFilename] = useState("");
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });

  // State to control the open status of the dialog
  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect( (uploadType) => {
    // Initialize with the filename from localStorage
    const savedFilename = localStorage.getItem('selectedFilename_' + uploadType);
    if (savedFilename) {
      setSelectedFilename(savedFilename);
    }
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setSelectedFilename(file.name);
    localStorage.setItem('selectedFilename_' + uploadType, file.name); // Save filename to localStorage
  };

   const handleUpload = async () => {
    if (!selectedFile) {
      setAlert({ message: 'Please, select a file to upload', color: 'warning' });
      setOpenDialog(true);
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile); // Append uploaded file

    const cleanupLocalData = () => {
        if (uploadType === 'sales') {
              localStorage.removeItem('salesData');
              localStorage.removeItem('selectedFilename_sales');
        } else if (uploadType === 'marketing') {
              localStorage.removeItem('marketingData');
              localStorage.removeItem('selectedFilename_marketing');
        }
    }

    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const fullUrl = `${baseUrl}/${uploadType}/upload_and_process?project=${encodeURIComponent(project)}`;
    console.log("Backend API url: ", fullUrl);

    try {
        // Adjust the URL and endpoint according to your FastAPI configuration
        const response = await axios.post(fullUrl, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": `Bearer ${authInfo.accessToken}`
            }
        });

        // If onUpload callback is provided, pass the processed data to it
        if (onUpload && response.data) {
            cleanupLocalData();
            onUpload(uploadType, response.data); // Trigger the callback after upload
        }
//        console.log('baseUrl: ', baseUrl);
//        console.log('fullURL: ', fullUrl);
//        console.log('File uploaded successfully!');
        setAlert({ message: 'File uploaded successfully!', color: 'success' });
        setOpenDialog(true);

        } catch (error) {
            console.error('Error uploading file:', error);
            setAlert({ message: 'Error uploading file', color: 'error' });
            setOpenDialog(true);

            // Delete data upon error on uploading data
            console.log("uploadType: ", uploadType);
            cleanupLocalData();
        }
  };

  return (
   <>
     <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <MDAlert color={alert.color}>{alert.message}</MDAlert>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-end' }}>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    <MDBox height="60px" display="flex" justifyContent="left" alignItems="center" gap="10px">
      <InputLabel>
            <MDInput type="file" onChange={handleFileChange}/>
      </InputLabel>
      <MDTypography variant="body2" fontSize="0.9rem">
            File selected: <b>{selectedFilename}</b>
      </MDTypography>

   </MDBox >
   <MDBox height="50px" display="flex" justifyContent="left" alignItems="center">
      <MDButton component={Link} color="dark" onClick={handleUpload}>Upload {uploadType} data</MDButton>
    </MDBox >
  </>
  );
}

export default FileUpload;
