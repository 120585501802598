import React from 'react';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import Card from "@mui/material/Card";

import budgetAllocator from "assets/images/budgetAllocator.png";

import ChatBar from 'layouts/ChatBar';

const BudgetAllocatorTab = () => {
    return (
        <MDBox p={3} pb={3} pr={5}>
            <MDTypography variant="h3" >
                Budget Allocator
            </MDTypography>
            <ChatBar />
            <br/>
            <Card >
             <MDTypography variant="body2" > </MDTypography>
             <div class="centerSampleImg">
                <img src={budgetAllocator} alt=""/>
            </div>
            <br/>
          </Card>
        </MDBox>
    );
};

export default BudgetAllocatorTab;